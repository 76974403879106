import './style.css';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import * as dat from 'lil-gui';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

/**
 * Loaders
 */
const loadingBarElement = document.querySelector('.loading-bar');

let sceneReady = false;
const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    // Wait a little
    window.setTimeout(() => {
      // Animate overlay

      // Update loadingBarElement
      loadingBarElement.classList.add('ended');
      loadingBarElement.style.transform = '';
    }, 500);

    window.setTimeout(() => {
      sceneReady = true;
    }, 2000);
  },

  // Progress
  (itemUrl, itemsLoaded, itemsTotal) => {
    // Calculate the progress and update the loadingBarElement
    const progressRatio = itemsLoaded / itemsTotal;
    loadingBarElement.style.transform = `scaleX(${progressRatio})`;
  }
);

/**
 * Loaders
 */
const dracoLoader = new DRACOLoader(loadingManager);
dracoLoader.setDecoderPath('/draco/');
const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);

/**
 * Base
 */
// Debug
const gui = new dat.GUI();
const debugObject = {
  color: 0x0d74e3,
  showRack: false,
  showJerriccan: false,
};

// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();

/**
 * Update all materials
 */
const updateAllMaterials = () => {
  scene.traverse((child) => {
    if (
      child instanceof THREE.Mesh &&
      child.material instanceof THREE.MeshStandardMaterial
    ) {
      child.material.envMapIntensity = debugObject.envMapIntensity;

      child.castShadow = true;
      child.receiveShadow = true;

      if (child.material.name.startsWith('Rack')) {
        child.material.visible = debugObject.showRack;
      }

      if (child.material.name === 'Jerrican') {
        child.material.visible = debugObject.showJerriccan;
      }

      if (child.material.name.startsWith('Skin')) {
        child.material.color.set(debugObject.color);
      }
    }
  });
};

const updateColor = (color) => {
  debugObject.color = color;
  updateAllMaterials();
};

window.updateColor = updateColor;

const updateRack = () => {
  const value = document.getElementById('rack').checked;

  debugObject.showRack = value;
  updateAllMaterials();
};

window.updateRack = updateRack;

const updateJerrican = () => {
  const value = document.getElementById('jerrican').checked;

  debugObject.showJerriccan = value;
  updateAllMaterials();
};

window.updateJerrican = updateJerrican;

/**
 * Textures map
 */
const textureLoader = new THREE.TextureLoader();

const repeat = new THREE.Vector2(30, 30);
const folder = 'concrete3';

const colorTexture = textureLoader.load(`/textures/${folder}/basecolor.jpg`);
colorTexture.wrapS = THREE.RepeatWrapping;
colorTexture.wrapT = THREE.RepeatWrapping;
colorTexture.anisotropy = 4;
colorTexture.encoding = THREE.sRGBEncoding;
colorTexture.repeat = repeat;

const normalTexture = textureLoader.load(`/textures/${folder}/normal.jpg`);
normalTexture.wrapS = THREE.RepeatWrapping;
normalTexture.wrapT = THREE.RepeatWrapping;
normalTexture.anisotropy = 4;
normalTexture.repeat = repeat;

const roughnessTexture = textureLoader.load(
  `/textures/${folder}/roughness.jpg`
);
roughnessTexture.wrapS = THREE.RepeatWrapping;
roughnessTexture.wrapT = THREE.RepeatWrapping;
roughnessTexture.anisotropy = 4;
roughnessTexture.repeat = repeat;

const heightTexture = textureLoader.load(`/textures/${folder}/height.png`);
heightTexture.wrapS = THREE.RepeatWrapping;
heightTexture.wrapT = THREE.RepeatWrapping;
heightTexture.anisotropy = 4;
heightTexture.repeat = repeat;

const ambientTexture = textureLoader.load(
  `/textures/${folder}/ambientOcclusion.jpg`
);
ambientTexture.wrapS = THREE.RepeatWrapping;
ambientTexture.wrapT = THREE.RepeatWrapping;
ambientTexture.anisotropy = 4;
ambientTexture.repeat = repeat;

gui.addColor(debugObject, 'color').onChange(() => {
  updateAllMaterials();
});
/**
 * Models
 */
let model;
gltfLoader.load('/models/camper/camper.gltf', (gltf) => {
  model = gltf.scene;
  model.scale.set(0.5, 0.5, 0.5);
  model.position.set(0, -1.25, 0);
  // model.rotation.x = Math.PI * 0.5;

  scene.add(model);

  // model.traverse(function (object) {
  //   if (object.isMesh) object.castShadow = true;
  // });

  gui
    .add(gltf.scene.rotation, 'x')
    .min(-Math.PI)
    .max(Math.PI)
    .step(0.001)
    .name('rotation');

  gui.add(gltf.scene.position, 'x').min(-40).max(40).step(0.001).name('x');
  gui.add(gltf.scene.position, 'y').min(-40).max(40).step(0.001).name('y');
  gui.add(gltf.scene.position, 'z').min(-40).max(40).step(0.001).name('z');

  updateAllMaterials();
});

/**
 * Points of interest
 */
const raycaster = new THREE.Raycaster();
const points = [
  {
    position: new THREE.Vector3(2.0, 4.9, 0.6),
    element: document.querySelector('.point-0'),
  },
  {
    position: new THREE.Vector3(4.5, 2.4, 2),
    element: document.querySelector('.point-1'),
  },
  {
    position: new THREE.Vector3(-3.8, 2.5, 0.8),
    element: document.querySelector('.point-2'),
  },
];

// Floor
const floor = new THREE.Mesh(
  new THREE.PlaneGeometry(100, 100),
  new THREE.MeshStandardMaterial({
    roughness: 0.5,
    color: 0xffffff,
    metalness: 0.2,
    bumpScale: 0.0005,
    map: colorTexture,
    normalMap: normalTexture,
    roughnessMap: roughnessTexture,
    aoMap: ambientTexture,
    displacementMap: heightTexture,
    displacementScale: 0.1,
    name: 'Floor',
  })
);
floor.geometry.setAttribute(
  'uv2',
  new THREE.Float32BufferAttribute(floor.geometry.attributes.uv.array, 2)
);
floor.rotation.x = -Math.PI * 0.5;
floor.position.y = 0;
floor.receiveShadow = true;

scene.add(floor);

// Fog
const fog = new THREE.Fog('#111', 1, 40);
scene.fog = fog;

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 1.5);
scene.add(ambientLight);

const bulbLight = new THREE.PointLight(0xffffff, 500, 22, 1);
bulbLight.position.set(0, 20, 0);
bulbLight.castShadow = true;
bulbLight.shadow.camera.far = 15;
bulbLight.shadow.mapSize.set(1024, 1024);
bulbLight.shadow.normalBias = 0.05;
scene.add(bulbLight);

const hemiLight = new THREE.HemisphereLight(0xffeeb1, 0x080820, 0.3);
scene.add(hemiLight);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener('resize', () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  1000
);
camera.position.set(-8, 10, 9);
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.target.set(0, 1, 0);
controls.maxPolarAngle = Math.PI / 2 - 0.1;
controls.minPolarAngle = Math.PI / 4;
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
  preserveDrawingBuffer: true,
});
renderer.physicallyCorrectLights = true;
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMapping = THREE.LinearToneMapping;
renderer.toneMappingExposure = 2;
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.setClearColor('#111');

gui
  .add(renderer, 'toneMapping', {
    No: THREE.NoToneMapping,
    Linear: THREE.LinearToneMapping,
    Reinhard: THREE.ReinhardToneMapping,
    Cineon: THREE.CineonToneMapping,
    ACESFilmic: THREE.ACESFilmicToneMapping,
  })
  .onFinishChange(() => {
    renderer.toneMapping = Number(renderer.toneMapping);
    updateAllMaterials();
  });
gui.add(renderer, 'toneMappingExposure').min(0).max(10).step(0.001);

gui.hide();

/**
 * Animate
 */

const tick = () => {
  // Update controls
  controls.update();

  if (sceneReady) {
    const loader = document.getElementsByClassName('loading')[0];
    loader.classList.add('hide');
    // Go through each point
    for (const point of points) {
      // Get 2D screen position
      const screenPosition = point.position.clone();
      screenPosition.project(camera);

      // Set the raycaster
      raycaster.setFromCamera(screenPosition, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);

      // No intersect found
      if (intersects.length === 0) {
        // Show
        point.element.classList.add('visible');
      }

      // Intersect found
      else {
        // Get the distance of the intersection and the distance of the point
        const intersectionDistance = intersects[0].distance;
        const pointDistance = point.position.distanceTo(camera.position);

        // Intersection is close than the point
        if (intersectionDistance < pointDistance) {
          // Hide
          point.element.classList.remove('visible');
        }
        // Intersection is further than the point
        else {
          // Show
          point.element.classList.add('visible');
        }
      }

      const translateX = screenPosition.x * sizes.width * 0.5;
      const translateY = -screenPosition.y * sizes.height * 0.5;
      point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
    }
  }

  // Render
  renderer.render(scene, camera);
  // console.log(camera.position);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

// Axes helper
// const axesHelper = new THREE.AxesHelper(3);
// axesHelper.position.set(0, 5, 0);
// scene.add(axesHelper);

tick();
